/*******************************
     User Global Variables
*******************************/
@primaryColor        : @red;
@secondaryColor        : @black;
@positiveColor           : @red;

@pageBackground: #ffffff;

/* Field */
@fieldMargin: 0em 0em 0em

@green : #000
@red              : #ed1b24;
@black              : #2b2a29;
