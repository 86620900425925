/*******************************
         Site Overrides
*******************************/
@primaryColor        : @red;
@secondaryColor        : @black;

@red              : #ed1b24;
@black              : #2b2a29;













/*******************************
        Simsaw Site Overrides
*******************************/
.boxShadowNone{
box-shadow:none !important;
}
.enquiryForm.ui.form.extraSpaceNone{
padding:none !important;
margin:none !important;
}
.ui.form .field .prompt.label {
   position: absolute;
   right: 0;
    z-index: 99;
    top: 29px;
}
.enquiryForm.ui.form .fields,.customForm.ui.form .fields{
margin-bottom:0 !important;
}
.enquiryForm.ui.form .field,.customForm.ui.form .field{
margin-bottom:15px !important;
}
.customForm .fields .field label {
    display: none !important;
}
.ui.form .fields{
margin-bottom:0px !important;
}
 .ui.form .field{
margin-bottom:15px !important;

}
.subTitle {
    font-size: 18px !important;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif !important;
}
.titleMain {
    font-size: 40px !important;
    font-weight: 700;
     font-family: 'Open Sans', sans-serif !important;

}
.borderNone {
    border: 0 !important;
    box-shadow: none !important;
}
#AboutUs {
    padding-top: 12%;
}
.link.step{
    padding: 15px 15px !important;
}
h4 {
    font-size: 28px !important;
}

.subTitle {
    font-size: 48px;
    position: relative;
}

