/*******************************
         Site Overrides
*******************************/
.ui.compact.table th{
min-width:100px;
}
@media (max-width: 767px){
.tableResponsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}}
