/*******************************
         Site Overrides
*******************************/
/* Button */
.ui.primary.button,.ui.secondary.button,.ui.button{
padding: 12px 60px;
}

.ui.primary.small.button{
padding: 10px 30px;
}

.ui.primary.button , .ui.secondary.button,.ui.button {
    text-transform: uppercase;
}

.btnTopFix {
    position: absolute !important;
    top: 20px;
    right: 20px;
    z-index: 999 !important;
    color: #ffffff !important;
}
@media (max-width:767px){
.ui.primary.button,.ui.secondary.button,.ui.button{
padding: 12px 30px;
}
}
